import type { RouteRecordRaw } from 'vue-router';

import { OrganizationRights } from '@/client';
import { useConfigStore } from '@/stores/ConfigStore.ts';

import { haveUserAccess } from '@/composables/Flags.ts';
import { hasOrganizationRights } from '@/composables/Auth';

import DefaultLayout from '@/layouts/DefaultLayout.vue';

export const organizationRoutes: RouteRecordRaw[] = [
  {
    path: '/organization',
    name: 'organization',
    meta: { layout: DefaultLayout },
    component: () => import('@/pages/Organization/OrganizationPage.vue'),

    children: [
      {
        path: '',
        name: 'organization-default',
        component: () => import('@/pages/Organization/Users.vue'),
        beforeEnter: async () => {
          if (haveUserAccess()) {
            return { name: 'organization-users' };
          }

          return { name: 'organization-frameworks' };
        },
      },
      {
        path: 'users',
        name: 'organization-users',
        component: () => import('@/pages/Organization/Users.vue'),
        beforeEnter: async () => {
          if (haveUserAccess()) {
            return true;
          }

          return { name: 'organization-frameworks' };
        },
      },
      {
        path: 'frameworks',
        name: 'organization-frameworks',
        component: () => import('@/pages/Organization/Frameworks.vue'),
      },
      {
        path: 'frameworks/:frameworkCode',
        name: 'organization-framework-detail',
        component: () => {
          // TODO: Remove this when temporary feature flag is removed
          const configStore = useConfigStore();
          if (configStore.contentManagementEnabled) {
            return import('@/pages/Organization/FrameworkPage.vue');
          } else {
            return import('@/pages/Organization/OldFrameworkPage.vue');
          }
          // TODO: Remove this when temporary feature flag is removed
        },
      },
      {
        path: 'customization',
        name: 'organization-customization',
        component: () => import('@/pages/Organization/Taxonomy.vue'),
      },
      {
        path: 'settings',
        name: 'organization-settings',
        component: () => import('@/pages/Organization/Settings.vue'),
        beforeEnter: async () => {
          if (hasOrganizationRights(OrganizationRights.OrganizationsConfigRead)) {
            return true;
          }

          return { name: 'organization-frameworks' };
        },
      },
    ],
  },
];
