import { useConfigStore } from '@/stores/ConfigStore';

const ensureVendorsEnabled = async () => {
  const configStore = useConfigStore();

  if (!configStore.configData || !configStore.vendorsEnabled) return { name: 'home' };
};

export const useGuards = () => ({
  ensureVendorsEnabled,
});
