import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export const tokenRoutes: RouteRecordRaw[] = [
  {
    path: '/tokens',
    name: 'tokens',
    meta: { layout: DefaultLayout },
    component: () => import('@/pages/TokenPage.vue'),
  },
];
