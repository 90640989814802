import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export const dashboardRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    alias: '/dashboard',
    meta: { layout: DefaultLayout },
    component: () => import('@/pages/DashboardPage.vue'),
  },
];
