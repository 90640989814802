/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.2.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const OrganizationRights = {
    OrganizationsUpdate: 'organizations:update',
    OrganizationsUsersRead: 'organizations:users:read',
    OrganizationsUsersUpdate: 'organizations:users:update',
    OrganizationsUsersStatusUpdate: 'organizations:users:status:update',
    OrganizationsUsersInvite: 'organizations:users:invite',
    OrganizationsUserRolesAssign: 'organizations:user_roles:assign',
    OrganizationsUserRolesDelete: 'organizations:user_roles:delete',
    OrganizationsUserRolesUpdate: 'organizations:user_roles:update',
    OrganizationsProjectsFullAccess: 'organizations:projects:full_access',
    OrganizationsProjectsRead: 'organizations:projects:read',
    OrganizationsConfigRead: 'organizations:config:read',
    OrganizationsConfigUpdate: 'organizations:config:update',
    OrganizationsConfigPatch: 'organizations:config:patch',
    OrganizationsFrameworksRead: 'organizations:frameworks:read',
    OrganizationsQuotaRead: 'organizations:quota:read',
    OrganizationsVendorsRead: 'organizations:vendors:read',
    OrganizationsVendorsCreate: 'organizations:vendors:create',
    OrganizationsVendorsUpdate: 'organizations:vendors:update',
    OrganizationsVendorsDelete: 'organizations:vendors:delete',
    OrganizationsVendorsDocumentsUpload: 'organizations:vendors:documents:upload',
    OrganizationsVendorsDocumentsRead: 'organizations:vendors:documents:read',
    OrganizationsVendorsDocumentsDelete: 'organizations:vendors:documents:delete',
    OrganizationsVendorsDocumentsReadFileUrl: 'organizations:vendors:documents:read_file_url',
    OrganizationsVendorsDocumentsDownload: 'organizations:vendors:documents:download',
    OrganizationsTemplatesUpload: 'organizations:templates:upload',
    OrganizationsTemplatesRead: 'organizations:templates:read',
    OrganizationsTemplatesDelete: 'organizations:templates:delete',
    OrganizationsFilesRead: 'organizations:files:read',
    OrganizationsTagsRead: 'organizations:tags:read',
    OrganizationsInternalMetricsRead: 'organizations:internal:metrics:read'
} as const;

export type OrganizationRights = typeof OrganizationRights[keyof typeof OrganizationRights];



