import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export const notificationRoutes: RouteRecordRaw[] = [
  {
    path: '/notifications',
    name: 'notifications',
    meta: { layout: DefaultLayout },
    component: () => import('@/pages/NotificationsPage.vue'),
  },
];
