import type { RouteRecordRaw } from 'vue-router';

import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { useGuards } from '@/router/utils/guards.ts';

const { ensureVendorsEnabled } = useGuards();

export const vendorRoutes: RouteRecordRaw[] = [
  {
    path: '/vendors',
    name: 'vendors',
    meta: { layout: DefaultLayout },
    component: () => import('@/pages/VendorsPage.vue'),
    beforeEnter: async () => {
      await ensureVendorsEnabled();
    },
    children: [
      {
        path: '',
        name: 'vendors-overview',
        component: () => import('@/pages/Vendors/VendorsOverview.vue'),
      },
      {
        path: 'vendor-detail/:vendorId',
        name: 'vendor-detail',
        component: () => import('@/pages/Vendors/VendorDetailPage.vue'),
      },
    ],
  },
];
