<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useAuthStore } from '@/stores/AuthStore.ts';
import { useNotificationStore } from '@/stores/NotificationStore.ts';

import OrganizationHeader from './content/OrganizationHeader.vue';
import ProjectHeader from './content/ProjectHeader.vue';
import AdminHeader from './content/AdminHeader.vue';

import {
  notificationsOutlined,
  notificationsUnreadOutlined,
  closeOutlined,
  menuOutlined,
} from '@/assets/icons/materialSymbols/MaterialSymbols.ts';

import { headerIds } from '@/utils/DataTestIds.ts';

const emit = defineEmits(['toggleDrawer']);

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();
const notificationStore = useNotificationStore();
const showUnread = ref(false);
const showDeviceBanner = ref(true);

function toNotifications() {
  router.push('/notifications');
}

function drawerToggle() {
  emit('toggleDrawer');
}

const isOrganizationRoute = computed(() =>
  ['organization-'].some((prefix) => route.name?.toString().includes(prefix)),
);
const isProjectRoute = computed(() =>
  ['project-', 'risk-detail', 'asset-detail', 'test-detail'].some((prefix) =>
    route.name?.toString()?.includes(prefix),
  ),
);

const isAdminRoute = computed(() =>
  ['admin-'].some((prefix) => route.name?.toString().includes(prefix)),
);

watch(
  () => notificationStore.newNotificationCount,
  (newV) => {
    if (newV > 0) {
      showUnread.value = true;
    } else {
      showUnread.value = false;
    }
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <q-header v-if="route.name !== 'not-found'" bordered class="text-white q-py-xs">
    <div v-if="showDeviceBanner" class="row col-12 device-banner items-center lt-sm">
      <div class="row col-11 justify-center">
        <span>The full Modulos Platform features are enabled for bigger resolution screens.</span>
      </div>
      <q-space />
      <q-icon
        :name="closeOutlined"
        size="22px"
        color="blue-grey-4"
        @click="showDeviceBanner = false"
      />
    </div>
    <q-toolbar v-if="$q.screen.lt.sm">
      <q-icon :name="menuOutlined" size="22px" color="blue-grey-4" @click="drawerToggle" />
      <q-space />
      <div class="org-name__container row items-center justify-center">
        <span>{{
          authStore.user && 'organization' in authStore.user
            ? authStore.user.organization.name
            : null
        }}</span>
      </div>
      <q-space />
      <div class="notifications__container row items-center">
        <q-icon
          v-if="!showUnread"
          :name="notificationsOutlined"
          :data-test-id="headerIds.notificationsIcon"
          size="24px"
          color="blue-grey-7"
          @click="toNotifications"
        />
        <q-icon
          v-if="showUnread"
          :name="notificationsUnreadOutlined"
          :data-test-id="headerIds.notificationsUnreadIcon"
          color="red-5"
          size="24px"
          @click="toNotifications"
        />
      </div>
    </q-toolbar>

    <q-toolbar v-else>
      <span
        v-if="
          (authStore.user && route.name === 'dashboard') ||
          (authStore.user && route.name === 'home')
        "
        class="greetings gt-xs"
        >{{ 'Hello, ' + authStore.user.firstname + ' ' + authStore.user.lastname + '!' }}</span
      >
      <q-space
        v-if="
          (authStore.user && route.name === 'dashboard') ||
          (authStore.user && route.name === 'home')
        "
      />
      <OrganizationHeader v-if="isOrganizationRoute" />
      <ProjectHeader v-if="isProjectRoute" />
      <AdminHeader v-if="isAdminRoute" />
      <q-space />
      <div class="org-name__container row items-center justify-center">
        <span>{{
          authStore.user && 'organization' in authStore.user
            ? authStore.user.organization.name
            : null
        }}</span>
      </div>
      <div class="notifications__container row items-center">
        <q-icon
          v-if="!showUnread"
          :name="notificationsOutlined"
          :data-test-id="headerIds.notificationsIcon"
          size="24px"
          color="blue-grey-7"
          @click="toNotifications"
        />
        <q-icon
          v-if="showUnread"
          :name="notificationsUnreadOutlined"
          :data-test-id="headerIds.notificationsUnreadIcon"
          color="red-5"
          size="24px"
          @click="toNotifications"
        />
      </div>
    </q-toolbar>
  </q-header>
</template>

<style lang="scss" scoped>
.q-header {
  background: $white;
  border: none;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);

  .device-banner {
    @include paragraph-01(400, rgba(0, 0, 0, 0.85));
    margin-top: -10px;
    padding: 15px 20px;
    border-radius: 4px;
    border: 1px solid #ffe58f;
    background: #fffbe6;
  }

  :deep(svg) {
    cursor: pointer;
  }
  .q-toolbar {
    height: 62px;
    padding: 10px 36px;
    @media screen and (max-width: 1250px) {
      height: auto;
    }
    .header__title {
      @include paragraph-01(400, $secondary-800);
      opacity: 0.4;
    }
    .greetings {
      @include paragraph-02(700, $common-4);
      margin-left: 16px;
    }
    .user {
      span {
        @include paragraph-01(400, $secondary-800);
      }
      .q-avatar {
        background: $secondary-100;
        span {
          @include paragraph-02(400, $secondary-800);
        }
      }
    }
    button {
      width: 48px;
      height: 48px;
      background: #fafafa;
      line-height: normal;
    }
    button:hover {
      background: $primary-50;
    }

    .org-name__container {
      height: 100%;

      @include respond-to-min($breakpoint-sm-min) {
        padding: 0 15px;
        margin-right: 10px;
        border-right: 1px solid $secondary-100;
        justify-content: flex-end;
      }

      span {
        @include paragraph-01(400, $secondary-500);
      }
    }

    .notifications__container {
      height: 100%;
      position: relative;

      .dot-icon {
        position: absolute;
        top: -4px;
        right: 0;
      }
    }
  }
}
</style>
