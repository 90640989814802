import type { RouteRecordRaw } from 'vue-router';

import AuthLayout from '@/layouts/AuthLayout.vue';

export const authRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login-page',
    meta: { layout: AuthLayout },
    component: () => import('@/pages/LoginPage.vue'),
  },
  {
    path: '/oauth2-redirect',
    name: 'oauth2-redirect',
    meta: { layout: AuthLayout },
    component: () => import('@/pages/OAuth2RedirectPage.vue'),
  },
  {
    path: '/oauth2-signup-redirect',
    name: 'oauth2-signup-redirect',
    meta: { layout: AuthLayout },
    component: () => import('@/pages/OAuth2SignupRedirectPage.vue'),
  },
];
