import type { RouteRecordRaw } from 'vue-router';

import { OrganizationRights } from '@/client';
import { hasOrganizationRights, isSuperAdmin } from '@/composables/Auth.ts';

import DefaultLayout from '@/layouts/DefaultLayout.vue';

export const adminRoutes: RouteRecordRaw[] = [
  {
    path: '/admin',
    name: 'admin',
    meta: { layout: DefaultLayout },
    component: () => import('@/pages/Admin/AdminPage.vue'),
    beforeEnter: async () => {
      if (!hasOrganizationRights(OrganizationRights.OrganizationsInternalMetricsRead)) {
        return { name: 'home' };
      }
    },
    children: [
      {
        path: '',
        name: 'admin-organizations',
        component: () => import('@/pages/Admin/OrganizationsPage.vue'),
      },
      {
        path: 'frameworks',
        name: 'admin-frameworks',
        component: () => import('@/pages/Admin/FrameworksPage.vue'),
        beforeEnter: () => {
          if (!isSuperAdmin()) {
            return { name: 'admin-organizations' };
          }
        },
      },
      {
        path: 'frameworks/:frameworkCode',
        name: 'admin-framework-detail',
        component: () => import('@/pages/Admin/FrameworkPage.vue'),
        beforeEnter: () => {
          if (!isSuperAdmin()) {
            return { name: 'admin-organizations' };
          }
        },
      },
    ],
  },
];
