/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.2.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Rights = {
    ProjectsRead: 'projects:read',
    ProjectsUpdate: 'projects:update',
    ProjectsDelete: 'projects:delete',
    ProjectsUserRolesAssign: 'projects:user_roles:assign',
    ProjectsUserRolesDelete: 'projects:user_roles:delete',
    ProjectsDashboardRead: 'projects:dashboard:read',
    ProjectsCommentsRead: 'projects:comments:read',
    ProjectsCommentsCreate: 'projects:comments:create',
    ProjectsLogsRead: 'projects:logs:read',
    ProjectsAiSystemLifeCycleDashboardRead: 'projects:ai_system_life_cycle_dashboard:read',
    ProjectsFrameworksCreate: 'projects:frameworks:create',
    ProjectsFrameworksRead: 'projects:frameworks:read',
    ProjectsRequirementsRead: 'projects:requirements:read',
    ProjectsEuAiActSettingsRead: 'projects:eu_ai_act:settings:read',
    ProjectsEuAiActSettingsUpdate: 'projects:eu_ai_act:settings:update',
    ProjectsRisksRead: 'projects:risks:read',
    ProjectsRisksCreate: 'projects:risks:create',
    ProjectsRisksUpdate: 'projects:risks:update',
    ProjectsRisksAssigneeUpdate: 'projects:risks:assignee:update',
    ProjectsRisksTreatmentRead: 'projects:risks:treatment:read',
    ProjectsRisksTreatmentCreate: 'projects:risks:treatment:create',
    ProjectsRisksTreatmentUpdate: 'projects:risks:treatment:update',
    ProjectsRisksTreatmentStrategyRead: 'projects:risks:treatment:strategy:read',
    ProjectsRisksTreatmentStrategyCreate: 'projects:risks:treatment:strategy:create',
    ProjectsRisksTreatmentStrategyUpdate: 'projects:risks:treatment:strategy:update',
    ProjectsRisksTreatmentStrategyDelete: 'projects:risks:treatment:strategy:delete',
    ProjectsRisksControlsRead: 'projects:risks:controls:read',
    ProjectsRisksControlsDelete: 'projects:risks:controls:delete',
    ProjectsControlsRisksAdd: 'projects:controls:risks:add',
    ProjectsControlsRead: 'projects:controls:read',
    ProjectsControlsUpdate: 'projects:controls:update',
    ProjectsControlsAssigneeUpdate: 'projects:controls:assignee:update',
    ProjectsControlsAssessmentsRead: 'projects:controls:assessments:read',
    ProjectsControlsAssessmentsCreate: 'projects:controls:assessments:create',
    ProjectsControlsAssessmentsAgentRun: 'projects:controls:assessments:agent:run',
    ProjectsEvidencesRead: 'projects:evidences:read',
    ProjectsEvidencesUpdate: 'projects:evidences:update',
    ProjectsEvidencesDelete: 'projects:evidences:delete',
    ProjectsEvidencesDownload: 'projects:evidences:download',
    ProjectsEvidencesUpload: 'projects:evidences:upload',
    ProjectsEvidencesSuggestionsAgentRun: 'projects:evidences:suggestions:agent:run',
    ProjectsUsersRead: 'projects:users:read',
    ProjectsReviewsRead: 'projects:reviews:read',
    ProjectsReviewsRequest: 'projects:reviews:request',
    ProjectsReviewsUpdate: 'projects:reviews:update',
    ProjectsReviewsSetDecision: 'projects:reviews:set_decision',
    ProjectsReviewsCancelPending: 'projects:reviews:cancel_pending',
    ProjectsReviewsForceUpdateStatus: 'projects:reviews:force_update_status',
    ProjectsAssetCardsCreate: 'projects:asset_cards:create',
    ProjectsAssetCardsRead: 'projects:asset_cards:read',
    ProjectsAssetCardsUpdate: 'projects:asset_cards:update',
    ProjectsAssetCardsDownload: 'projects:asset_cards:download',
    ProjectsAssetCardsAssigneeUpdate: 'projects:asset_cards:assignee:update',
    ProjectsTestingLogsCreate: 'projects:testing_logs:create',
    ProjectsTestingSourcesRead: 'projects:testing_sources:read',
    ProjectsTestingSourcesMetricsRead: 'projects:testing_sources:metrics:read',
    ProjectsTestingSourcesMetricsCreate: 'projects:testing_sources:metrics:create',
    ProjectsTestingTestRead: 'projects:testing_test:read',
    ProjectsTestingTestCreate: 'projects:testing_test:create',
    ProjectsTestingTestUpdate: 'projects:testing_test:update',
    ProjectsTestingTestDelete: 'projects:testing_test:delete',
    ProjectsTestingTestResultsRead: 'projects:testing_test:results:read',
    ProjectsTestingTestResultsRun: 'projects:testing_test:results:run',
    ProjectsTestingTestControlsRead: 'projects:testing_test:controls:read',
    ProjectsTestingTestControlsDelete: 'projects:testing_test:controls:delete',
    ProjectsTestingTestControlsCreate: 'projects:testing_test:controls:create',
    ProjectsQuestionnaireRead: 'projects:questionnaire:read',
    ProjectsQuestionnaireUpdate: 'projects:questionnaire:update',
    ProjectsQuestionsRead: 'projects:questions:read',
    ProjectsQuestionsAnswer: 'projects:questions:answer'
} as const;

export type Rights = typeof Rights[keyof typeof Rights];



