import { createRouter, createWebHistory } from 'vue-router';

import { useAuthStore } from '@/stores/AuthStore.ts';

import { useAuth } from '@/composables/Auth.ts';
import { useConfigStore } from '@/stores/ConfigStore.ts';
import { captureError } from '@/composables/Sentry.ts';

import { authRoutes } from '@/router/modules/authRoutes.ts';
import { adminRoutes } from '@/router/modules/adminRoutes.ts';
import { dashboardRoutes } from '@/router/modules/dashboardRoutes.ts';
import { errorRoutes } from '@/router/modules/errorRoutes.ts';
import { notificationRoutes } from '@/router/modules/notificationRoutes.ts';
import { organizationRoutes } from '@/router/modules/organizationRoutes.ts';
import { projectRoutes } from '@/router/modules/projectRoutes.ts';
import { tokenRoutes } from '@/router/modules/tokenRoutes.ts';
import { vendorRoutes } from '@/router/modules/vendorRoutes.ts';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...authRoutes,
    ...adminRoutes,
    ...dashboardRoutes,
    ...errorRoutes,
    ...notificationRoutes,
    ...organizationRoutes,
    ...projectRoutes,
    ...tokenRoutes,
    ...vendorRoutes,
    {
      path: '/:catchAll(.*)',
      name: 'not-found',
      component: () => import('@/pages/ProblemPages/NotFound.vue'),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const configStore = useConfigStore();
  const { logout } = useAuth();

  const access_token = localStorage.getItem('access_token');
  const refresh_token = localStorage.getItem('refresh_token');
  const user = localStorage.getItem('user');
  const parsedUser = user ? JSON.parse(user) : null;

  const publicPages = [
    'login-page',
    'oauth2-redirect',
    'oauth2-signup-redirect',
    'no-organization',
    'token-expired',
    'wrong-email',
    'problem',
  ];

  const toName = to.name ? to.name.toString() : null;
  const isPublicPage = toName ? publicPages.includes(toName) : false;

  document.title = 'Modulos';

  // 1. Ensure user has accepted terms before proceeding
  if (parsedUser && !parsedUser.terms_accepted && !isPublicPage) {
    logout();
    return;
  }

  // 2. Ensure user has tokens, otherwise try refreshing or logout
  if (!access_token && !isPublicPage) {
    if (refresh_token) {
      await authStore.getAccessTokenFromRefreshToken();
      const newAccessToken = localStorage.getItem('access_token');
      if (!newAccessToken) {
        logout();
        return;
      }
    } else {
      logout();
      return;
    }
  }

  // 3. Ensure user has an organization
  if (parsedUser && !parsedUser.organization && toName !== 'no-organization') {
    next({ name: 'no-organization' });
    return;
  }

  // 4. Ensure user object exist and organization configuration exist
  if (!isPublicPage && (!authStore.user || !configStore.configData)) {
    try {
      await authStore.getMeAndOrgConfig();
    } catch (error) {
      captureError(error, {
        message: 'Component: Router, File: index.ts, Error: getMeAndOrgConfig',
      });

      logout();
      return;
    }
  }

  next();
});

export default router;
