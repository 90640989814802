import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { AssetCardNature, AssetCardTypes, Status } from '@/client';

export const usePaginationStore = defineStore('pagination', () => {
  const nameFilter = ref('');
  const statusFilter = ref<null | Status>(null);
  const frameworkQuery = ref<null | string>(null);
  const pageFilter = ref<number | null>(null);
  const tagFilter = ref<string | null>(null);
  const assignedToMe = ref<boolean | undefined>(undefined);
  const assignedToMeForReview = ref<boolean | undefined>(undefined);

  const assetTypeFilter = ref<AssetCardTypes | null>(null);
  const assetNatureFilter = ref<AssetCardNature | null>(null);

  const testStatusFilter = ref<boolean | null>(null);

  const setNameFilter = (name: string) => {
    nameFilter.value = name;
  };

  const setFrameworkQuery = (framework: string | null) => {
    frameworkQuery.value = framework;
  };

  const setStatusFilter = (status: Status | null) => {
    statusFilter.value = status;
  };

  const setAssignedToMe = (value?: boolean) => {
    assignedToMe.value = value;
  };

  const setAssignedForReview = (value?: boolean) => {
    assignedToMeForReview.value = value;
  };

  const setPage = (page: number | null) => {
    pageFilter.value = page;
  };

  const setTagFilter = (tags: string | null) => {
    tagFilter.value = tags;
  };

  const setAssetTypeFilter = (type: AssetCardTypes | null) => {
    assetTypeFilter.value = type;
  };

  const setAssetNatureFilter = (type: AssetCardNature | null) => {
    assetNatureFilter.value = type;
  };

  const setTestStatusFilter = (status: boolean | null) => {
    testStatusFilter.value = status;
  };

  const resetProjectPagination = () => {
    nameFilter.value = '';
    statusFilter.value = null;
    frameworkQuery.value = null;
    pageFilter.value = null;
    tagFilter.value = null;
    assignedToMe.value = false;
    assignedToMeForReview.value = false;
    assetTypeFilter.value = null;
    assetNatureFilter.value = null;
    testStatusFilter.value = null;
  };

  return {
    nameFilter,
    setNameFilter,
    frameworkQuery,
    pageFilter,
    assignedToMe,
    setFrameworkQuery,
    statusFilter,
    setStatusFilter,
    setPage,
    setAssignedToMe,
    tagFilter,
    setTagFilter,
    setAssignedForReview,
    assignedToMeForReview,
    setAssetTypeFilter,
    setAssetNatureFilter,
    assetTypeFilter,
    assetNatureFilter,
    setTestStatusFilter,
    testStatusFilter,
    resetProjectPagination,
  };
});
