<script setup lang="ts">
import { onBeforeUnmount, onMounted } from 'vue';
import { useQuasar } from 'quasar';

import { captureError } from '@/composables/Sentry.ts';
import { startStream } from '@/composables/EventStream.ts';
import { ForbiddenError } from '@/composables/ApiError';
import { errorMessage } from '@/composables/Notify';

import { useAuthStore } from '@/stores/AuthStore.ts';
import { useStylesStore } from '@/stores/StylesStore.ts';

import Drawer from '@/components/DefaultLayout/DefaultDrawer.vue';
import Footer from '@/components/DefaultLayout/DefaultFooter.vue';
import Header from '@/components/DefaultLayout/DefaultHeader.vue';

const $q = useQuasar();
const store = useAuthStore();
const stylesStore = useStylesStore();

const ctrl = new AbortController();

function toggleDrawer() {
  stylesStore.toggleDrawer(!stylesStore.drawerOpened);
}

onMounted(async () => {
  try {
    const user = store.user;

    if (!user) return;

    await store.userLastAccess();

    if (window.clarity) {
      window.clarity('set', 'organization_name', user.organization.name);
      window.clarity('set', 'user_email', user.email);
    }
  } catch (error) {
    if (error instanceof ForbiddenError) {
      errorMessage(error.message);

      return;
    }

    captureError(error, {
      message: 'Component: DefaultLayout, Hook: onMounted',
    });
  }

  try {
    startStream();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response && e.response.headers.get('Content-Type') === 'application/json') {
      return;
    }

    captureError(e, {
      message: 'Component: DefaultLayout, Function: startStream',
    });
  }

  const darkMode = localStorage.getItem('darkMode');
  if (!darkMode) return;

  $q.dark.set(JSON.parse(darkMode));
});

onBeforeUnmount(() => {
  ctrl.abort();
});
</script>

<template>
  <q-layout view="lHr lpR lfr">
    <Header
      v-if="$q.screen.lt.sm && stylesStore.drawerOpened ? false : true"
      :style="{ left: $q.screen.lt.sm ? 0 : stylesStore.drawerOpened ? '260px' : '77px' }"
      @toggle-drawer="toggleDrawer"
    />
    <Drawer v-if="$q.screen.lt.sm ? stylesStore.drawerOpened : true" />
    <q-page-container
      :style="{ paddingLeft: $q.screen.lt.sm ? 0 : stylesStore.drawerOpened ? '260px' : '77px' }"
    >
      <router-view />
    </q-page-container>
    <Footer />
  </q-layout>
</template>

<style lang="scss">
// Do not make scoped (styles bug).

.q-layout {
  background: $secondary-50;
  :deep(.q-page-container) {
    background: transparent !important;
    padding: 0 !important;
  }
}
</style>
