/* tslint:disable */
/* eslint-disable */
/**
 * Modulos Platform API
 * API for the Modulos Platform.
 *
 * The version of the OpenAPI document: 1.2.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CreateToken } from '../model';
// @ts-ignore
import type { GrantTypes } from '../model';
// @ts-ignore
import type { HTTPValidationError } from '../model';
// @ts-ignore
import type { ModulosPlatformBackendSchemasAuthorizationsToken } from '../model';
// @ts-ignore
import type { ModulosPlatformBackendSchemasTokensToken } from '../model';
// @ts-ignore
import type { Rights } from '../model';
// @ts-ignore
import type { TokenResponse } from '../model';
// @ts-ignore
import type { TokenStatus } from '../model';
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Completes Login flow.
         * @summary Api Token
         * @param {GrantTypes} grantType 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenApiAuthTokenPost: async (grantType: GrantTypes, code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('apiTokenApiAuthTokenPost', 'grantType', grantType)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiTokenApiAuthTokenPost', 'code', code)
            const localVarPath = `/api/auth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.set('code', code as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiates Login. Redirects to oauth2_login_scheme.b2c.authorize_url
         * @summary Authorize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeApiAuthAuthorizeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Initiates Signup. Redirects to oauth2_signup_scheme.b2c.authorize_url
         * @summary Authorize Token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeTokenApiAuthAuthorizeTokenGet: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('authorizeTokenApiAuthAuthorizeTokenGet', 'token', token)
            const localVarPath = `/api/auth/authorize/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an API token for the current user.  Args:     user (User): The current user     duration (int): The validity duration in days  Returns:     Token: The API token
         * @summary Create Api Token
         * @param {CreateToken} createToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiTokenApiAuthApiPost: async (createToken: CreateToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createToken' is not null or undefined
            assertParamExists('createApiTokenApiAuthApiPost', 'createToken', createToken)
            const localVarPath = `/api/auth/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an API token for the current user.  Args:     user (User): The current user     token_id (str): The token id  Returns:     ErrorResponse:
         * @summary Delete Api Token
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTokenApiAuthApiDelete: async (tokenId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('deleteApiTokenApiAuthApiDelete', 'tokenId', tokenId)
            const localVarPath = `/api/auth/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)

            if (tokenId !== undefined) {
                localVarQueryParameter['token_id'] = tokenId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dummy
         * @param {GrantTypes} x 
         * @param {Rights} rights 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyApiAuthDummyGet: async (x: GrantTypes, rights: Rights, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'x' is not null or undefined
            assertParamExists('dummyApiAuthDummyGet', 'x', x)
            // verify required parameter 'rights' is not null or undefined
            assertParamExists('dummyApiAuthDummyGet', 'rights', rights)
            const localVarPath = `/api/auth/dummy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (x !== undefined) {
                localVarQueryParameter['x'] = x;
            }

            if (rights !== undefined) {
                localVarQueryParameter['rights'] = rights;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all API tokens for the current user.  Args:     user (User): The current user  Returns:     List[Token]: The list of API tokens
         * @summary Get Api Tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTokensApiAuthApiGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the status of the current user\'s token.  Args:     user (User): The current user  Returns:     TokenStatus: The token status
         * @summary Get Token Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenStatusApiAuthTokenStatusGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/token/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication B2COauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "B2COauth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutApiAuthLogoutGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh Token
         * @param {GrantTypes} grantType 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokenApiAuthRefreshPost: async (grantType: GrantTypes, refreshToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('refreshTokenApiAuthRefreshPost', 'grantType', grantType)
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('refreshTokenApiAuthRefreshPost', 'refreshToken', refreshToken)
            const localVarPath = `/api/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (refreshToken !== undefined) { 
                localVarFormParams.set('refresh_token', refreshToken as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Completes Registration flow.
         * @summary Register
         * @param {GrantTypes} grantType 
         * @param {string} code 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerApiAuthRegisterPost: async (grantType: GrantTypes, code: string, token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('registerApiAuthRegisterPost', 'grantType', grantType)
            // verify required parameter 'code' is not null or undefined
            assertParamExists('registerApiAuthRegisterPost', 'code', code)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('registerApiAuthRegisterPost', 'token', token)
            const localVarPath = `/api/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.set('code', code as any);
            }
    
            if (token !== undefined) { 
                localVarFormParams.set('token', token as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Completes Login flow.
         * @summary Api Token
         * @param {GrantTypes} grantType 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTokenApiAuthTokenPost(grantType: GrantTypes, code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTokenApiAuthTokenPost(grantType, code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.apiTokenApiAuthTokenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Initiates Login. Redirects to oauth2_login_scheme.b2c.authorize_url
         * @summary Authorize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeApiAuthAuthorizeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeApiAuthAuthorizeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authorizeApiAuthAuthorizeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Initiates Signup. Redirects to oauth2_signup_scheme.b2c.authorize_url
         * @summary Authorize Token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeTokenApiAuthAuthorizeTokenGet(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeTokenApiAuthAuthorizeTokenGet(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authorizeTokenApiAuthAuthorizeTokenGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create an API token for the current user.  Args:     user (User): The current user     duration (int): The validity duration in days  Returns:     Token: The API token
         * @summary Create Api Token
         * @param {CreateToken} createToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApiTokenApiAuthApiPost(createToken: CreateToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModulosPlatformBackendSchemasAuthorizationsToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApiTokenApiAuthApiPost(createToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.createApiTokenApiAuthApiPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an API token for the current user.  Args:     user (User): The current user     token_id (str): The token id  Returns:     ErrorResponse:
         * @summary Delete Api Token
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiTokenApiAuthApiDelete(tokenId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiTokenApiAuthApiDelete(tokenId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.deleteApiTokenApiAuthApiDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Dummy
         * @param {GrantTypes} x 
         * @param {Rights} rights 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dummyApiAuthDummyGet(x: GrantTypes, rights: Rights, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dummyApiAuthDummyGet(x, rights, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.dummyApiAuthDummyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all API tokens for the current user.  Args:     user (User): The current user  Returns:     List[Token]: The list of API tokens
         * @summary Get Api Tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiTokensApiAuthApiGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ModulosPlatformBackendSchemasTokensToken>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiTokensApiAuthApiGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.getApiTokensApiAuthApiGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the status of the current user\'s token.  Args:     user (User): The current user  Returns:     TokenStatus: The token status
         * @summary Get Token Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenStatusApiAuthTokenStatusGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenStatusApiAuthTokenStatusGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.getTokenStatusApiAuthTokenStatusGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutApiAuthLogoutGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutApiAuthLogoutGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.logoutApiAuthLogoutGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Refresh Token
         * @param {GrantTypes} grantType 
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshTokenApiAuthRefreshPost(grantType: GrantTypes, refreshToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTokenApiAuthRefreshPost(grantType, refreshToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.refreshTokenApiAuthRefreshPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Completes Registration flow.
         * @summary Register
         * @param {GrantTypes} grantType 
         * @param {string} code 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerApiAuthRegisterPost(grantType: GrantTypes, code: string, token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerApiAuthRegisterPost(grantType, code, token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.registerApiAuthRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * Completes Login flow.
         * @summary Api Token
         * @param {AuthenticationApiApiTokenApiAuthTokenPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTokenApiAuthTokenPost(requestParameters: AuthenticationApiApiTokenApiAuthTokenPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenResponse> {
            return localVarFp.apiTokenApiAuthTokenPost(requestParameters.grantType, requestParameters.code, options).then((request) => request(axios, basePath));
        },
        /**
         * Initiates Login. Redirects to oauth2_login_scheme.b2c.authorize_url
         * @summary Authorize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeApiAuthAuthorizeGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.authorizeApiAuthAuthorizeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Initiates Signup. Redirects to oauth2_signup_scheme.b2c.authorize_url
         * @summary Authorize Token
         * @param {AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeTokenApiAuthAuthorizeTokenGet(requestParameters: AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.authorizeTokenApiAuthAuthorizeTokenGet(requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an API token for the current user.  Args:     user (User): The current user     duration (int): The validity duration in days  Returns:     Token: The API token
         * @summary Create Api Token
         * @param {AuthenticationApiCreateApiTokenApiAuthApiPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApiTokenApiAuthApiPost(requestParameters: AuthenticationApiCreateApiTokenApiAuthApiPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ModulosPlatformBackendSchemasAuthorizationsToken> {
            return localVarFp.createApiTokenApiAuthApiPost(requestParameters.createToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an API token for the current user.  Args:     user (User): The current user     token_id (str): The token id  Returns:     ErrorResponse:
         * @summary Delete Api Token
         * @param {AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiTokenApiAuthApiDelete(requestParameters: AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteApiTokenApiAuthApiDelete(requestParameters.tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dummy
         * @param {AuthenticationApiDummyApiAuthDummyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyApiAuthDummyGet(requestParameters: AuthenticationApiDummyApiAuthDummyGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.dummyApiAuthDummyGet(requestParameters.x, requestParameters.rights, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all API tokens for the current user.  Args:     user (User): The current user  Returns:     List[Token]: The list of API tokens
         * @summary Get Api Tokens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiTokensApiAuthApiGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<ModulosPlatformBackendSchemasTokensToken>> {
            return localVarFp.getApiTokensApiAuthApiGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the status of the current user\'s token.  Args:     user (User): The current user  Returns:     TokenStatus: The token status
         * @summary Get Token Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenStatusApiAuthTokenStatusGet(options?: RawAxiosRequestConfig): AxiosPromise<TokenStatus> {
            return localVarFp.getTokenStatusApiAuthTokenStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutApiAuthLogoutGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.logoutApiAuthLogoutGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh Token
         * @param {AuthenticationApiRefreshTokenApiAuthRefreshPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokenApiAuthRefreshPost(requestParameters: AuthenticationApiRefreshTokenApiAuthRefreshPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenResponse> {
            return localVarFp.refreshTokenApiAuthRefreshPost(requestParameters.grantType, requestParameters.refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Completes Registration flow.
         * @summary Register
         * @param {AuthenticationApiRegisterApiAuthRegisterPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerApiAuthRegisterPost(requestParameters: AuthenticationApiRegisterApiAuthRegisterPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenResponse> {
            return localVarFp.registerApiAuthRegisterPost(requestParameters.grantType, requestParameters.code, requestParameters.token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiTokenApiAuthTokenPost operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiApiTokenApiAuthTokenPostRequest
 */
export interface AuthenticationApiApiTokenApiAuthTokenPostRequest {
    /**
     * 
     * @type {GrantTypes}
     * @memberof AuthenticationApiApiTokenApiAuthTokenPost
     */
    readonly grantType: GrantTypes

    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiApiTokenApiAuthTokenPost
     */
    readonly code: string
}

/**
 * Request parameters for authorizeTokenApiAuthAuthorizeTokenGet operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest
 */
export interface AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGet
     */
    readonly token: string
}

/**
 * Request parameters for createApiTokenApiAuthApiPost operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiCreateApiTokenApiAuthApiPostRequest
 */
export interface AuthenticationApiCreateApiTokenApiAuthApiPostRequest {
    /**
     * 
     * @type {CreateToken}
     * @memberof AuthenticationApiCreateApiTokenApiAuthApiPost
     */
    readonly createToken: CreateToken
}

/**
 * Request parameters for deleteApiTokenApiAuthApiDelete operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest
 */
export interface AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiDeleteApiTokenApiAuthApiDelete
     */
    readonly tokenId: string
}

/**
 * Request parameters for dummyApiAuthDummyGet operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiDummyApiAuthDummyGetRequest
 */
export interface AuthenticationApiDummyApiAuthDummyGetRequest {
    /**
     * 
     * @type {GrantTypes}
     * @memberof AuthenticationApiDummyApiAuthDummyGet
     */
    readonly x: GrantTypes

    /**
     * 
     * @type {Rights}
     * @memberof AuthenticationApiDummyApiAuthDummyGet
     */
    readonly rights: Rights
}

/**
 * Request parameters for refreshTokenApiAuthRefreshPost operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiRefreshTokenApiAuthRefreshPostRequest
 */
export interface AuthenticationApiRefreshTokenApiAuthRefreshPostRequest {
    /**
     * 
     * @type {GrantTypes}
     * @memberof AuthenticationApiRefreshTokenApiAuthRefreshPost
     */
    readonly grantType: GrantTypes

    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiRefreshTokenApiAuthRefreshPost
     */
    readonly refreshToken: string
}

/**
 * Request parameters for registerApiAuthRegisterPost operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiRegisterApiAuthRegisterPostRequest
 */
export interface AuthenticationApiRegisterApiAuthRegisterPostRequest {
    /**
     * 
     * @type {GrantTypes}
     * @memberof AuthenticationApiRegisterApiAuthRegisterPost
     */
    readonly grantType: GrantTypes

    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiRegisterApiAuthRegisterPost
     */
    readonly code: string

    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiRegisterApiAuthRegisterPost
     */
    readonly token: string
}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * Completes Login flow.
     * @summary Api Token
     * @param {AuthenticationApiApiTokenApiAuthTokenPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiTokenApiAuthTokenPost(requestParameters: AuthenticationApiApiTokenApiAuthTokenPostRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiTokenApiAuthTokenPost(requestParameters.grantType, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initiates Login. Redirects to oauth2_login_scheme.b2c.authorize_url
     * @summary Authorize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authorizeApiAuthAuthorizeGet(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authorizeApiAuthAuthorizeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Initiates Signup. Redirects to oauth2_signup_scheme.b2c.authorize_url
     * @summary Authorize Token
     * @param {AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authorizeTokenApiAuthAuthorizeTokenGet(requestParameters: AuthenticationApiAuthorizeTokenApiAuthAuthorizeTokenGetRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authorizeTokenApiAuthAuthorizeTokenGet(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an API token for the current user.  Args:     user (User): The current user     duration (int): The validity duration in days  Returns:     Token: The API token
     * @summary Create Api Token
     * @param {AuthenticationApiCreateApiTokenApiAuthApiPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public createApiTokenApiAuthApiPost(requestParameters: AuthenticationApiCreateApiTokenApiAuthApiPostRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).createApiTokenApiAuthApiPost(requestParameters.createToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an API token for the current user.  Args:     user (User): The current user     token_id (str): The token id  Returns:     ErrorResponse:
     * @summary Delete Api Token
     * @param {AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public deleteApiTokenApiAuthApiDelete(requestParameters: AuthenticationApiDeleteApiTokenApiAuthApiDeleteRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).deleteApiTokenApiAuthApiDelete(requestParameters.tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dummy
     * @param {AuthenticationApiDummyApiAuthDummyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public dummyApiAuthDummyGet(requestParameters: AuthenticationApiDummyApiAuthDummyGetRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).dummyApiAuthDummyGet(requestParameters.x, requestParameters.rights, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all API tokens for the current user.  Args:     user (User): The current user  Returns:     List[Token]: The list of API tokens
     * @summary Get Api Tokens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getApiTokensApiAuthApiGet(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getApiTokensApiAuthApiGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the status of the current user\'s token.  Args:     user (User): The current user  Returns:     TokenStatus: The token status
     * @summary Get Token Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getTokenStatusApiAuthTokenStatusGet(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getTokenStatusApiAuthTokenStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logoutApiAuthLogoutGet(options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).logoutApiAuthLogoutGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh Token
     * @param {AuthenticationApiRefreshTokenApiAuthRefreshPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public refreshTokenApiAuthRefreshPost(requestParameters: AuthenticationApiRefreshTokenApiAuthRefreshPostRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).refreshTokenApiAuthRefreshPost(requestParameters.grantType, requestParameters.refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Completes Registration flow.
     * @summary Register
     * @param {AuthenticationApiRegisterApiAuthRegisterPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public registerApiAuthRegisterPost(requestParameters: AuthenticationApiRegisterApiAuthRegisterPostRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).registerApiAuthRegisterPost(requestParameters.grantType, requestParameters.code, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }
}

