import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export const projectRoutes: RouteRecordRaw[] = [
  {
    path: '/projects',
    name: 'projects',
    meta: { layout: DefaultLayout },
    component: () => import('@/pages/ProjectsPage.vue'),
  },
  {
    path: '/projects/new-project',
    name: 'new-project',
    meta: { layout: DefaultLayout },
    component: () => import('@/pages/NewProjectPage.vue'),
  },
  {
    path: '/projects/project-detail/:id',
    name: 'project-detail',
    meta: { layout: DefaultLayout },
    component: () => import('@/pages/ProjectDetailPage.vue'),
    children: [
      {
        path: '',
        name: 'project-dashboard',
        component: () => import('@/components/ProjectDetail/Dashboard.vue'),
      },
      {
        path: 'requirements',
        name: 'project-requirements',
        component: () => import('@/components/ProjectDetail/Requirements.vue'),
      },
      {
        path: 'controls',
        name: 'project-controls',
        component: () => import('@/components/ProjectDetail/Controls.vue'),
      },
      {
        path: 'settings',
        name: 'project-settings',
        component: () => import('@/components/ProjectDetail/Settings.vue'),
      },
      {
        path: 'risks',
        name: 'project-risks',
        component: () => import('@/components/ProjectDetail/Risks.vue'),
      },
      {
        path: 'risk-detail/:riskId',
        name: 'risk-detail',
        component: () => import('@/pages/RiskDetailPage.vue'),
      },
      {
        path: 'evidence',
        name: 'project-evidence',
        component: () => import('@/components/ProjectDetail/Evidence.vue'),
      },

      {
        path: 'assets',
        name: 'project-assets',
        component: () => import('@/components/ProjectDetail/Assets.vue'),
      },
      {
        path: 'asset-detail/:assetId',
        name: 'asset-detail',
        component: () => import('@/pages/AssetDetailPage.vue'),
      },
      {
        path: 'testing',
        name: 'project-testing',
        component: () => import('@/components/ProjectDetail/Testing.vue'),
      },
      {
        path: 'test-detail/:testId',
        name: 'test-detail',
        component: () => import('@/pages/TestingDetailPage.vue'),
      },
    ],
  },
];
