<script setup lang="ts">
import { useRoute } from 'vue-router';
import { isSuperAdmin } from '@/composables/Auth.ts';

const route = useRoute();
</script>

<template>
  <div class="menu">
    <q-btn flat class="path" :to="`/admin`" :class="route.path === '/admin' ? 'active' : ''">
      Organizations
    </q-btn>
    <q-btn
      v-if="isSuperAdmin()"
      flat
      class="path"
      :to="`/admin/frameworks`"
      :class="route.path.includes('frameworks') ? 'active' : ''"
    >
      Frameworks
    </q-btn>
  </div>
</template>

<style scoped lang="scss">
.menu {
  a {
    @include paragraph-02(400, $secondary-500);
    text-transform: none;
    padding: 4px;
    margin: 0 16px;
  }
  .active {
    @include paragraph-02(700, $secondary-600);
    position: relative;
  }
  .active::after {
    content: '';
    width: 100%;
    height: 3px;
    background: $secondary-600;
    position: absolute;
    bottom: -15px;
    right: 0;
  }
}
</style>
