import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

export const errorRoutes: RouteRecordRaw[] = [
  {
    path: '/wrong-email',
    name: 'wrong-email',
    component: () => import('@/pages/ProblemPages/WrongEmail.vue'),
  },
  {
    path: '/token-expired',
    name: 'token-expired',
    component: () => import('@/pages/ProblemPages/TokenExpired.vue'),
  },
  {
    path: '/service-down',
    name: 'service-down',
    component: () => import('@/pages/ProblemPages/ServiceDown.vue'),
  },
  {
    path: '/not-found',
    name: 'not-found',
    meta: { layout: DefaultLayout },
    component: () => import('@/pages/ProblemPages/NotFound.vue'),
  },
  {
    path: '/problem',
    name: 'problem',
    component: () => import('@/pages/ProblemPages/ProblemPage.vue'),
  },
  {
    path: '/no-organization',
    name: 'no-organization',
    component: () => import('@/pages/ProblemPages/NoOrganization.vue'),
  },
];
